async function register(userData) {
  try {
    let requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: userData,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, requestOptions);
    return await response.json();
  } catch (error) {
    throw error;
  }
}

export {
  register,
}