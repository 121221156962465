import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import './contactInfo.css';
import faqData from './contactInfo.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
// import theme from '../../styles'
import Lottie from "lottie-react";
import ClimbingShoes from '../../assets/lottie/Climbing Chalk Bag.json';

const ContactInfo = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [currentDay, setCurrentDay] = useState('');

  useEffect(() => {
    const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const getMSTDate = () => {
      const date = new Date();
      const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
      const mstTime = new Date(utcTime + 3600000 * -7); // Convert UTC to GMT-7 (MST)
      return mstTime;
    };
    const today = getMSTDate().getDay();
    setCurrentDay(daysOfWeek[today]);
  }, []);

  const toggleSection = (index) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  const getCurrentSchedule = () => {
    const daySchedule = faqData.faq[0].days.find(day => day.day === currentDay);
    return daySchedule ? daySchedule.schedule : 'Horario no disponible';
  };

  return (
    <Container>
      <Main>
        <Card href="mailto:contacto@bouldergrid.com">Email: contacto@bouldergrid.com  <StaticChevronIcon
          icon={faChevronRight}
        /></Card>
        <Card href="tel:+526623698663">Teléfono: (662) 369 8663 <StaticChevronIcon
          icon={faChevronRight}
        /></Card>

        {/* Adjust ul styling */}
        <Accordion>
          <li className={expandedSection === 0 ? 'ci-expanded' : ''}>
            <button
              type="button"
              onClick={() => toggleSection(0)}
              style={buttonStyle}
            >
              {`${currentDay}: ${getCurrentSchedule()}`}
              <ChevronIcon
                icon={faChevronRight}
                isExpanded={expandedSection === 0}
              />
            </button>

            <section
              className={`ci-content ${expandedSection === 0 ? 'ci-visible' : ''}`}
              id="acc0"
              aria-labelledby="accLink0"
              tabIndex="0"
            >
              {expandedSection === 0 && (
                <div>
                  <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>{faqData.faq[0].header}</p>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                      {faqData.faq[0].days.map((day, index) => (
                        <tr key={index}>
                          <td style={{ padding: '5px', borderBottom: index !== faqData.faq[0].days.length - 1 ? '1px solid #ccc' : 'none' }}>
                            <strong>{day.day}</strong>
                          </td>
                          <td style={{ padding: '5px', borderBottom: index !== faqData.faq[0].days.length - 1 ? '1px solid #ccc' : 'none' }}>
                            {day.schedule}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </section>
          </li>
        </Accordion>

        <Card href="https://maps.app.goo.gl/WCvPdHUDrcNoHfac7" target="_blank" rel="noopener noreferrer">Dirección: Naranjo 12, 83180, Hermosillo, Son.  <StaticChevronIcon
          icon={faChevronRight}
        /></Card>
      </Main>
      <LottieStyled animationData={ClimbingShoes} loop={true} />
    </Container>
  );
};

const buttonStyle = {
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '1rem',
  padding: '10px 20px',
  color: '#151618',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  textAlign: 'left',
  backgroundColor: '#f6f6f6',
  [`@media (max-width:600px)`]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

const LottieStyled = styled(Lottie)(() => ({
  maxWidth: '400px',
  [`@media (max-width:600px)`]: {
    maxWidth: '200px',
  },
}));

const ChevronIcon = styled(FontAwesomeIcon)(({ isExpanded }) => ({
  marginLeft: 40,
  color: '#2cdce6',
  transition: 'transform 0.3s ease',
  transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
}));

const StaticChevronIcon = styled(FontAwesomeIcon)(() => ({
  marginLeft: 40,
  color: '#2cdce6',
  transition: 'transform 0.3s ease',
}));

const Container = styled('div')(() => ({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 100,
  [`@media (max-width:600px)`]: {
    gap: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '30px 0',
  },
}));

// Styling for the ul element to match card width
const Accordion = styled('ul')(() => ({
  width: '100%',
  // backgroundColor: 'pink',
  maxWidth: '600px',  // Same as cards
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  [`@media (max-width:600px)`]: {
    // maxWidth: '350px',  // Same as cards on mobile
  },
}));

const Card = styled('a')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#f6f6f6',
  width: '100%',
  borderRadius: '1rem',
  padding: '10px 20px',
  color: '#151618',
  textDecoration: 'none',
  cursor: 'pointer',
  maxWidth: '600px',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  [`@media (max-width:600px)`]: {
    width: '350px',
  },
});

const Main = styled('main')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
}));

export default ContactInfo;
