import React from 'react'

const Button = ({ children, clase, onClick }) => {
  return (
    <button className={`btn ${clase}`} onClick={onClick}>
        {children}
    </button>
  )
}

export default Button