import React from 'react';
import { styled } from '@mui/material';

const ClasesCard = ({ title, text, img }) => {
  return (
    <CardContainer>
      <ClassImage src={img} alt={title} />
      <ClassTitle>{title}</ClassTitle>
      <ClassText>{text}</ClassText>
    </CardContainer>
  );
};

const CardContainer = styled('div')(() => ({
  textAlign: 'left',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

const ClassImage = styled('img')(() => ({
  borderRadius: '5px',
  boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',  // Matches the 'shadow-xl' class in Tailwind
  width: '100%',
  maxWidth: '300px',  // Adjusts the image size as needed
  marginBottom: '16px',
  [`@media (max-width:600px)`]: {
    maxWidth: '250px',  // Adjusts the image size as needed
},
}));

const ClassTitle = styled('h2')(() => ({
  fontSize: '26px',  // Matches your h2 styles
  fontWeight: 'bold',
  margin: '8px 0',
}));

const ClassText = styled('p')(() => ({
  fontSize: '16px',
  color: '#000',
  opacity: 0.75,
  margin: 0,
}));

export default ClasesCard;
