import React from 'react';
import useAuth from '../../auth/useAuth';

function Admin() {
  const { session } = useAuth();

  console.log('session: ', session.token);
  return (
    <>
      <h2>Admin (Protected)</h2>
      <div>Authenticated as {session.token}</div>
    </>
  );
}

export default Admin;
