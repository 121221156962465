import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const sectionRefs = {
    memberships: useRef(null),
    areas: useRef(null),
    clases: useRef(null),
    events: useRef(null),
    home: useRef(null),
    products: useRef(null),
    contactForm: useRef(null),
    faq: useRef(null),
  };

  return (
    <ScrollContext.Provider value={sectionRefs}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
