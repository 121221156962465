async function getProducts() {
  try {
    let requestOptions = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await fetch(
      'https://api.stripe.com/v1/products?limit=100',
      requestOptions
    );
    const result = await response.json();
    const products = result.data;

    // Fetch prices for each product
    const productPrices = await Promise.all(
      products.map(async (product) => {
        const priceResponse = await fetch(
          `https://api.stripe.com/v1/prices/${product.default_price}`,
          requestOptions
        );
        const priceResult = await priceResponse.json();
        return { ...product, price: priceResult.unit_amount / 100 }; // assuming the price is in cents
      })
    );

    return productPrices;
  } catch (error) {
    throw error;
  }
}

async function getProductById(id) {
  try {
    let requestOptions = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const productResponse = await fetch(
      `https://api.stripe.com/v1/products/${id}`,
      requestOptions
    );
    const product = await productResponse.json();

    console.log('PRODUCTREPONSE: ', productResponse);

    if (product.default_price) {
      const priceResponse = await fetch(
        `https://api.stripe.com/v1/prices/${product.default_price}`,
        requestOptions
      );
      const priceResult = await priceResponse.json();
      product.price = priceResult.unit_amount / 100; // assuming the price is in cents
      product.isRecurring = !!priceResult.recurring; // Determine if the price is recurring
    } else {
      product.price = 0; // Default price if not available
      product.isRecurring = false;
    }

    return product;
  } catch (error) {
    throw error;
  }
}

async function createCheckoutSession(priceId, mode, customerId) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        'line_items[0][price]': priceId,
        'line_items[0][quantity]': 1,
        mode: mode, // The correct mode must be passed here
        customer: customerId,
        success_url: window.location.origin + '/success',
        cancel_url: window.location.origin + '/cancel',
      }),
    };

    const response = await fetch(
      'https://api.stripe.com/v1/checkout/sessions',
      requestOptions
    );
    const session = await response.json();
    return session.id;
  } catch (error) {
    throw error;
  }
}

async function getStripeTransactions(customerId) {
  try {
    const requestOptions = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await fetch(
      `https://api.stripe.com/v1/charges?customer=${customerId}&limit=100`,
      requestOptions
    );
    const result = await response.json();
    // Filter only successful transactions
    const successfulTransactions = result.data.filter(
      (transaction) => transaction.status === 'succeeded'
    );

    return successfulTransactions;
  } catch (error) {
    throw error;
  }
}

export {
  getProducts,
  getProductById,
  createCheckoutSession,
  getStripeTransactions,
};
