import { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAuth } from '../api/auth';
import Swal from 'sweetalert2';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getSessionFromLocalStorage = () => {
    try {
      const savedSession = localStorage.getItem('session');
      return savedSession ? JSON.parse(savedSession) : null;
    } catch (error) {
      console.error('Error parsing session from localStorage', error);
      return null;
    }
  };

  const [session, setSession] = useState(getSessionFromLocalStorage);

  useEffect(() => {
    setSession(getSessionFromLocalStorage());
  }, []);

  const handleLogin = async (identifier, password) => {
    const userData = JSON.stringify({
      identifier: identifier,
      password: password,
    });

    try {
      const response = await userAuth(userData);
      const sessionResponse = {
        token: response.jwt,
        user: {
          email: response.user.email,
          fullName: response.user.fullName,
          customerId: response.user.customerId,
        },
      };
      console.log('seshresponse', sessionResponse);

      if (sessionResponse.token) {
        setSession(sessionResponse);
        localStorage.setItem('session', JSON.stringify(sessionResponse));
        const origin = location.state?.from?.pathname || '/dashboard';
        navigate(origin);
      } else {
        Swal.fire('Error', 'Inicio de sesión fallido', 'error');
      }
    } catch (err) {
      console.error('Login failed:', err);
      Swal.fire('Error', 'Ocurrió un error durante el inicio de sesión', 'error');
    }
  };

  const handleLogout = () => {
    setSession(null);
    localStorage.removeItem('session');
  };

  const value = {
    session,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
