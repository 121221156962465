import { styled } from '@mui/material';
import theme from '../../styles';

const PrimaryButton = ({ width, onClick, children, color, backgroundColor, padding, shadow, marginTop, disabled }) => {

  const Button = styled('button')(() => ({
    width: width || 'auto',
    padding: padding || '10px 90px',
    borderRadius: '10px',
    color: color || theme.color.light,
    backgroundColor: disabled ? theme.color.disabledBackground : (backgroundColor || theme.color.accent),
    boxShadow: shadow || '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: theme.fontFamily.secondary,
    transition: 'transform 0.3s ease-out',
    marginTop: marginTop || '0px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: 'none',
    opacity: disabled ? 0.6 : 1,
    '&:hover': {
      transform: disabled ? 'none' : 'translateY(-10px)',
    },
    '&:focus': {
      outline: 'none',
    },
  }));

  return (
    <Button onClick={disabled ? null : onClick} width={width} disabled={disabled}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
