import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProductById, createCheckoutSession } from '../../api/products';
import { loadStripe } from '@stripe/stripe-js';
import useAuth from '../../auth/useAuth';
import Lottie from "lottie-react";
import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import theme from '../../styles';
import LoadingAnimation from '../../assets/lottie/Animation - 1719783273631.json';
import PrimaryButton from '../../components/PrimaryButton'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const ProductDetailAndCheckout = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { session } = useAuth();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      handleGetProduct(id);
    }
  }, [id]);

  const handleGetProduct = async (productId) => {
    try {
      const product = await getProductById(productId);
      setProduct(product);
      setLoading(false);
    } catch (err) {
      console.log('Error fetching product:', err);
      setLoading(false);
    }
  };

  const handleCheckout = async () => {
    if (!session) {
      Swal.fire({
        title: 'Inicia sesión',
        text: 'Debes iniciar sesión para poder comprar este producto.',
        icon: 'warning',
        confirmButtonText: 'Iniciar sesión'
      }).then(() => {
        navigate('/login');
      });
      return;
    }

    const stripe = await stripePromise;
    const customerId = session.user.customerId;

    if (!customerId) {
      console.error('No customer ID found in session');
      return;
    }

    console.log('product here: ', product);

    try {
      const mode = product.isRecurring ? 'subscription' : 'payment';  // Determine the mode based on the product
      const sessionId = await createCheckoutSession(product.default_price, mode, customerId);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Lottie animationData={LoadingAnimation} loop={true} style={{ maxWidth: '200px', margin: 50 }} />
      </LoadingContainer>
    );
  }

  if (!product) {
    return <Message>Producto no encontrado</Message>;
  }

  return (
    <ProductDetailContainer>
      <ProductTitle>{product.name}</ProductTitle>
      {product.images && product.images.length > 0 && (
        <ProductImage src={product.images[0]} alt={product.name} />
      )}
      <ProductDescription>{product.description}</ProductDescription>
      <ProductPrice>Precio: ${product.price.toFixed(2)}</ProductPrice>
      <PrimaryButton onClick={handleCheckout} color={theme.color.textPrimary}>Comprar Ahora</PrimaryButton>
      <ActionButton onClick={() => navigate(-1)}>Volver</ActionButton>
    </ProductDetailContainer>
  );
};

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const ProductDetailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

const ProductTitle = styled('h1')({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
  marginBottom: '20px',
});

const ProductImage = styled('img')({
  width: '300px',
  height: '300px',
  marginBottom: '16px',
  borderRadius: '50%',
  objectFit: 'cover',
});

const ProductDescription = styled('p')({
  fontFamily: theme.fontFamily.secondary,
  color: theme.color.textPrimary,
  marginBottom: '20px',
  textAlign: 'center',
});

const ProductPrice = styled('p')({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
  marginBottom: '30px',
});

const ActionButton = styled('button')({
  color: theme.color.textPrimary,
  padding: '30px 20px',
  border: 'none',
  borderRadius: '10px',
  cursor: 'pointer',
  marginBottom: '10px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  '&:focus': {
    outline: 'none',
  },
});

const Message = styled('div')({
  fontFamily: theme.fontFamily.primary,
  color: theme.color.textPrimary,
  textAlign: 'center',
  padding: '20px',
});

export default ProductDetailAndCheckout;
