
function PrivacyNotice() {
  return (
    <>
      <h2>Privacy Notice</h2>
      <p>Privacy Notice text</p>
    </>
  );
}

export default PrivacyNotice;
