import React from 'react';
import { styled } from '@mui/material';
import ClasesCard from '../components/clasesCard';

import yoga from '../assets/clases/yoga.png';
import kids from '../assets/clases/kids.png';
import adults from '../assets/clases/adults.png';
import { useScroll } from '../components/ScrollContext'

const Clases = () => {
  const { clases } = useScroll();
  return (
    <ClasesContainer ref={clases}>
      <ClasesContent>
        <Title>Clases</Title>
        <ClasesGrid>
          <ClasesCard
            title='Yoga'
            text='Lunes a Jueves 19:00 (1hr)'
            img={yoga}
          />
          <ClasesCard
            title='Ni&ntilde;os'
            text='Lunes y Mi&eacute;rcoles 17:00 (1hr)'
            img={kids}
          />
          <ClasesCard
            title='Adultos'
            text='Martes y Viernes 18:00 (1hr)'
            img={adults}
          />
        </ClasesGrid>
      </ClasesContent>
    </ClasesContainer>
  );
};

const ClasesContainer = styled('div')(() => ({
  backgroundColor: '#FAD749',  // Matches your 'clases' background color
  padding: '200px 0',
  clipPath: 'polygon(0 25%, 100% 0, 100% 75%, 0 100%)',  // Maintain the unique background shape
  [`@media (max-width: 1290px)`]: {
    clipPath: 'polygon(0 15%, 100% 0, 100% 85%, 0 100%)', 
  },
  [`@media (max-width:680px)`]: {
    clipPath: 'polygon(0 5%, 100% 0, 100% 95%, 0 100%)',  // Maintain the unique background shape

  },
}));

const ClasesContent = styled('div')(() => ({
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  textAlign: 'center',
}));

const Title = styled('h1')(() => ({
  fontFamily: 'Inter, sans-serif',  // Use the same font as defined in your CSS
  fontSize: '32px',  // Matches your h1 style
  fontWeight: 'bold',
  color: 'black',  // Matches the text color used in your Tailwind styles
  marginBottom: '40px',  // Adjust as necessary
}));

const ClasesGrid = styled('div')(() => ({
  display: 'flex',
  gap: '80px',
  justifyContent: 'center',
  flexWrap: 'wrap',  // Ensure it wraps on smaller screens
  '@media (max-width: 900px)': {
    // gap: '15px',
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'center',
    // gap: '10px',
  },
}));

export default Clases;
