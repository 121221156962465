import React from 'react';
import { styled } from '@mui/material';
import ClasesCard from '../../components/clasesCard';
import Post1 from '../../assets/events/post1.png';
import Post2 from '../../assets/events/post2.png';
import Post3 from '../../assets/events/post3.png';
import Post4 from '../../assets/events/post4.png';
import Post5 from '../../assets/events/post5.png';
import Post6 from '../../assets/events/post6.png';
import { useScroll } from '../../components/ScrollContext';

const Events = () => {
  const { events } = useScroll();
  return (
    <ClasesContainer ref={events}>
      <ClasesContent>
        <Title>Eventos</Title>
        <ClasesGrid>
          <ClasesCard img={Post1} />
          <ClasesCard img={Post2} />
          <ClasesCard img={Post3} />
          <ClasesCard img={Post4} />
          <ClasesCard img={Post5} />
          <ClasesCard img={Post6} />
        </ClasesGrid>
      </ClasesContent>
    </ClasesContainer>
  );
};

const ClasesContainer = styled('div')(() => ({
  padding: '20px 0',
  width: '100%',
}));

const ClasesContent = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: 60,
}));

const Title = styled('h1')(() => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '32px',
  fontWeight: 'bold',
  color: 'black',
}));

const ClasesGrid = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns for large screens
  gap: '20px',
  justifyItems: 'center',  // Center items horizontally
  width: '100%',
  maxWidth: '1200px',  // Limit the grid width on larger screens

  // For medium screens (tablets), 2 items per row
  '@media (max-width: 900px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  
  // For small screens (mobile), 2 items per row
  '@media (max-width: 600px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',  // Ensures 2 items per row
    justifyItems: 'center',  // Center each card in the row
    gap: '15px',  // Adjust gap between the cards
  },

  // Optional: adjust for even smaller screens
  '@media (max-width: 300px)': {
    gridTemplateColumns: '1fr',  // 1 card per row for very small screens
    justifyItems: 'center',  // Center the card
  },
}));

export default Events;
