import Hero from '../../sections/hero.jsx';
import Memberships from '../../sections/Memberships/index.jsx';
import Areas from '../../sections/areas.jsx';
import Clases from '../../sections/clases.jsx';
import ContactSection from '../../sections/Contact';
import FAQ from '../../sections/FAQ';
import Events from '../../sections/Events/index.jsx';
import ContactInfo from '../../sections/ContactInfo/index.jsx';

function Home() {
  return (
    <>
      <Hero />
      <Areas />
      <Memberships />
      <Clases />
      <Events />
      <ContactSection />
      <ContactInfo/>
      <FAQ></FAQ>
    </>
  );
}

export default Home;
