import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import theme from '../../styles';
import Card from '../../components/ProductCard';
import { useScroll } from '../../components/ScrollContext';
import { getProducts } from '../../api/products';
import Lottie from "lottie-react";
import LoadingAnimation from '../../assets/lottie/Animation - 1719783273631.json';

const Memberships = () => {
  const { memberships } = useScroll();
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    handleGetProducts();
  }, []);

  const handleGetProducts = async () => {
    try {
      const response = await getProducts();
      setProductList(response);
      setLoading(false); // Set loading to false after data is fetched
      console.log('response of get products: ', response);
    } catch (err) {
      console.log('error: ', err);
      setLoading(false); // Stop loading even if there's an error
    }
  };

  const testFeatures = [
    'Acceso a muro de escalada',
    'Acceso a área de pesas',
    'Acceso a clase de yoga'
  ];

  const handleCardClick = (id) => {
    navigate(`/product/${id}`);
    console.log('prod id', id);
  };

  return (
    <MainContainer ref={memberships}>
      <Title>Membresías</Title>
      {loading ? (
        <Lottie animationData={LoadingAnimation} loop={true} style={{ maxWidth: '200px', margin: 50 }} />
      ) : (
        <ProductGrid>
          {productList.map((product) => (
            <Card
              key={product.id}
              nombre={product.name}
              img={product.images.length > 0 ? product.images[0] : ''}
              text={product.description}
              precio={`$${product.price.toFixed(2)}`}
              btntext="Seleccionar"
              features={testFeatures}
              onClick={() => handleCardClick(product.id)}
            />
          ))}
        </ProductGrid>
      )}
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  padding: '60px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30
}));

const Title = styled('h1')(() => ({
  marginBottom: '6px',
  fontFamily: theme.fontFamily.primary,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));

const ProductGrid = styled('div')(() => ({
  width: '100%',
  maxWidth: '1200px',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)', // 3 cards per row by default
  gap: '60px',
  justifyItems: 'center',

  // Media queries for responsiveness
  '@media (max-width: 900px)': {
    gridTemplateColumns: 'repeat(2, 1fr)', // 2 cards per row on smaller screens
    gap: '40px',
  },
  '@media (max-width: 600px)': {
    gridTemplateColumns: '1fr', // 1 card per row on very small screens
    gap: '30px',
  },
}));


export default Memberships;
