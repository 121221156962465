import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import theme from '../../styles';

function Success() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => {
      clearInterval(timer); // Clear the interval on component unmount
      clearTimeout(redirectTimeout); // Clear the timeout on component unmount
    };
  }, [navigate]);

  return (
    <MainContainer>
      <H2>Pago Exitoso</H2>
      <Message>
        Te redirigiremos a tu cuenta en {countdown} segundo
        {countdown !== 1 && 's'}...
      </Message>
    </MainContainer>
  );
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '100vh',
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
  marginBottom: '20px',
}));

const Message = styled('p')(() => ({
  fontFamily: theme.fontFamily.secondary,
  fontSize: '16px',
  color: theme.color.textSecondary,
}));

export default Success;
