import React from 'react';
import { styled } from '@mui/material';

const Area = ({ text, img }) => {
  return (
    <AreaContainer img={img}>
      <AreaContent>
        <Title>{text}</Title>
      </AreaContent>
    </AreaContainer>
  );
};

const AreaContainer = styled('div')(({ img }) => ({
  backgroundImage: `url(${img})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '300px',
  height: '300px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  color: 'white',
  overflow: 'hidden',
  transition: 'transform 300ms ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  // Responsive adjustments
  '@media (max-width: 900px)': {
    width: '250px',
    height: '250px',
  },
  '@media (max-width: 600px)': {
    width: '150px',
    height: '150px',
  },
}));

const AreaContent = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  opacity: 0,
  transition: 'opacity 300ms',
  '&:hover': {
    opacity: 1,
  },
}));

const Title = styled('h1')(() => ({
  fontSize: '32px', // Default font size for larger screens
  fontWeight: 'bold',
  userSelect: 'none', // Disable text selection
  // Responsive font size adjustments
  '@media (max-width: 900px)': {
    fontSize: '28px',
  },
  '@media (max-width: 600px)': {
    fontSize: '24px',
  },
}));

export default Area;
