import React from 'react';
import { styled } from '@mui/material';
import theme from '../styles';
import Area from '../components/area';
import hangboard from '../assets/areas/hangboard.png';
import monkey from '../assets/areas/monkey.png';
import pared from '../assets/areas/pared.png';
import patio from '../assets/areas/patio.png';
import pesas from '../assets/areas/pesas.png';
import spraywall from '../assets/areas/spraywall.png';
import yoga from '../assets/areas/yoga.png';
import { useScroll } from '../components/ScrollContext';

const Areas = () => {
    const { areas } = useScroll();
    return (
        <MainContainer ref={areas}>
            <Title>&Aacute;reas</Title>
            <AreaGrid>
                <Area
                    text="Hangboard"
                    img={hangboard}
                />
                <Area
                    text="Cross Training"
                    img={monkey}
                />
                <Area
                    text="Paredes"
                    img={pared}
                />
                <Area
                    text="Peso Libre"
                    img={pesas}
                />
                <Area
                    text="Spray Wall"
                    img={spraywall}
                />
                <Area
                    text="Estudio de Yoga"
                    img={yoga}
                />
                <Area
                    text="Patio"
                    img={patio}
                />
            </AreaGrid>
        </MainContainer>
    );
};

const MainContainer = styled('div')(() => ({
    width: '100%',
    padding: '60px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
}));

const Title = styled('h1')(() => ({
    marginBottom: '24px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: 'bold',
    color: theme.color.textPrimary,
}));

const AreaGrid = styled('div')(() => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // 4 areas per row on large screens
    gap: '32px',
    justifyContent: 'center', // Centers the grid container horizontally
    justifyItems: 'center', // Centers each item within the grid cells

    // Responsive design for medium screens (tablets)
    '@media (max-width: 1400px)': {
        gridTemplateColumns: 'repeat(3, 1fr)', // 2 areas per row on medium screens
    },
    '@media (max-width: 822px)': {
        gridTemplateColumns: 'repeat(2, 1fr)', // 2 areas per row on medium screens
    },
    // Responsive design for small screens (mobile phones)
    // '@media (max-width: 600px)': {
    //     gridTemplateColumns: 'repeat(1, 1fr)', // 1 area per row on small screens
    // },
}));

export default Areas;
