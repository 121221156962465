import React from 'react';
import { styled } from '@mui/material';
import Button from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ProductCard = ({ clase, nombre, precio, tiempo, btntext, terminos, promo, text, features, img, onClick }) => {
    return (
        <CardContainer className={clase}>
            <CardHeader>
                <ProductName>{nombre}</ProductName>
                <ProductImage src={img} alt={nombre} />
                <PriceContainer>
                    <ProductPrice>{precio}</ProductPrice>
                    <PriceDuration>{tiempo}</PriceDuration>
                </PriceContainer>
                <ProductDescription>{text}</ProductDescription>
            </CardHeader>
            {features && features.length > 0 && (
                <FeatureList>
                    {features.map((feature, index) => (
                        <FeatureItem key={index}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            {feature}
                        </FeatureItem>
                    ))}
                </FeatureList>
            )}
            <CustomButton onClick={onClick}>{btntext}</CustomButton>
            {terminos && <Terms>{terminos}</Terms>}
        </CardContainer>
    );
};

const CardContainer = styled('div')(() => ({
    backgroundColor: 'white',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '24px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'black',
    width: '300px',  // Set fixed width for all cards
    minWidth: '300px',  // Ensure minimum width
    maxWidth: '300px',  // Ensure maximum width to prevent overflow
    [`@media (max-width: 900px)`]: {
        width: '45vw',  // Adjust for smaller screens, 2 cards per row
    },
    [`@media (max-width: 600px)`]: {
        width: '90vw',  // Full width for mobile view, 1 card per row
    },
}));


const CardHeader = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}));

const ProductName = styled('h3')(() => ({
    fontWeight: 'bold',
    fontSize: '30px',
    textTransform: 'capitalize',
    marginBottom: '16px',
}));

const ProductImage = styled('img')(() => ({
    width: '80px',
    height: '80px',
    marginBottom: '16px',
    borderRadius: '50%',
    objectFit: 'cover',
}));

const PriceContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: '16px',
}));

const ProductPrice = styled('h2')(() => ({
    fontWeight: 'bold',
    fontSize: '34px',
    marginRight: '8px',
}));

const PriceDuration = styled('h5')(() => ({
    marginBottom: '4px',
    textTransform: 'lowercase',
}));

const ProductDescription = styled('p')(() => ({
    fontSize: '14px',
    textTransform: 'capitalize',
    opacity: 0.6,
    marginBottom: '16px',
}));

const FeatureList = styled('ul')(() => ({
    marginBottom: '16px',
}));

const FeatureItem = styled('li')(() => ({
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
}));

const CustomButton = styled(Button)(() => ({
    backgroundColor: 'black',
    color: 'white',
    textTransform: 'capitalize',
    marginTop: '16px',
    padding: '15px 35px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
    },
}));

const Terms = styled('h6')(() => ({
    fontSize: '12px',
    opacity: 0.6,
    marginTop: '16px',
}));

export default ProductCard;
