import { styled } from '@mui/material';
import theme from '../../styles';

const IconAndButton = ({ width, onClick, children, color, icon, backgroundColor, padding, shadow, marginTop }) => {

  const Button = styled('button')(({ width }) => ({
    width: width || 'auto',
    padding: padding || '10px 90px',
    borderRadius: 10,
    color: color || theme.color.light,
    backgroundColor: backgroundColor || theme.color.accent,
    boxShadow: shadow || '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: theme.fontFamily.secondary,
    transition: 'transform 0.3s ease-out',
    marginTop: marginTop || null,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',  // Space between icon and text
    '&:hover': {
      transform: 'translateY(-10px)',
    },
    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
      padding: padding || '10px 10px',
  },
  }));

  return (
    <Button onClick={onClick} width={width}>
      {icon && icon()} {/* Render the icon */}
      {children}
    </Button>
  );
};

export default IconAndButton;
