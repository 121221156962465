import { styled } from '@mui/material';
import theme from '../../styles';

function Cancel() {
  return (
    <MainContainer>
      <H2>Cancel</H2>
    </MainContainer>
  );
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
}));

export default Cancel;
