import React from 'react'
import { styled } from '@mui/material';
import theme from '../styles'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PrimaryButton from '../components/PrimaryButton';
import Slide1Image from '../assets/wall-panoramic.webp'
// import AnotherImage from '../assets/hero/Hero.png'

import { useScroll } from '../components/ScrollContext';

const Hero = () => {
    const { memberships } = useScroll();
    const location = useLocation();
    const navigate = useNavigate();

    var settings = {
        dots: false,
        arrows: false,
        swipe: false, // en caso de agregr mas slides, habilitar swipe
        // autoplay: true,
        // fade: true,
        // autoplaySpeed: 3000,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
    };

    const { home } = useScroll();


    const scrollToSection = (sectionRef) => {
        window.scrollTo({
            top: sectionRef.current.offsetTop - 73,
            behavior: 'smooth'
        });
    };

    const handleNavClick = (sectionRef) => {
        if (location.pathname !== '/' && location.pathname !== '/home' && location.pathname !== '/terms-and-conditions' && location.pathname !== '/privacy-policy') {
            navigate('/');
        } else {
            scrollToSection(sectionRef);
        }
    };
    return (
        <MainContainer ref={home}>
            <Slides {...settings}>
                <SlideContainer>
                    <Slide1>
                        <H1>Alcanza nuevos desafíos</H1>
                        <ButtonsContainer>
                            <NavLink to="/" onClick={() => handleNavClick(memberships)}>
                                <PrimaryButton backgroundColor={theme.color.blue} color={theme.color.light} padding='20px 90px'>
                                    Membresías
                                </PrimaryButton>
                            </NavLink>
                            <NavLink to="/login">
                                <PrimaryButton backgroundColor={theme.color.accent} color={theme.color.textPrimary} padding='20px 90px'>
                                    Iniciar sesión
                                </PrimaryButton>
                            </NavLink>
                        </ButtonsContainer>
                    </Slide1>
                </SlideContainer>
                <SlideContainer>
                    <Slide1>
                        <NavLink to="/" onClick={() => handleNavClick(memberships)}>
                            <PrimaryButton backgroundColor={theme.color.blue} color={theme.color.light} padding='20px 90px'>
                                Membresías
                            </PrimaryButton>
                        </NavLink>
                        <NavLink to="/login">
                            <PrimaryButton backgroundColor={theme.color.accent} color={theme.color.textPrimary} padding='20px 90px'>
                                Iniciar sesión
                            </PrimaryButton>
                        </NavLink>
                    </Slide1>
                </SlideContainer>
            </Slides>
        </MainContainer>
    )
}

const MainContainer = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const Slides = styled(Slider)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
}));

const SlideContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Slide1 = styled('div')(() => ({
    width: '100%',
    height: '91vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    backgroundImage: `url(${Slide1Image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // 30% black overlay
        zIndex: 1,
    },
    '> *': {
        zIndex: 2, // Ensure child elements are above the overlay
    },
}));


const ButtonsContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
        flexDirection: 'column',
        gap: 30,
    },
}));

const H1 = styled('h1')(() => ({
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: 'bold',
    fontSize: '50px',
    color: theme.color.light,
    textShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
}));

export default Hero