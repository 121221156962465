import AuthProvider from './auth/authProvider';
import './App.css';
import Navbar from './components/Navbar';
import Router from './routes';
import Footer from './sections/Footer/index.jsx';
import { ScrollProvider } from './components/ScrollContext.jsx';

function App() {
  return (
    <AuthProvider>
      <ScrollProvider>
        <Navbar />
        <Router />
        <Footer />
      </ScrollProvider>
    </AuthProvider>
  );
}

export default App;
