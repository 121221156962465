function TermsAndConditions() {
  return (
    <>
      <h2>Terms and Conditions</h2>
      <p>Terms and Conditions text</p>
    </>
  );
}

export default TermsAndConditions;
