import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProductById, createCheckoutSession } from '../../api/products';
import { loadStripe } from '@stripe/stripe-js';
import useAuth from '../../auth/useAuth';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);


const Checkout = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { session } = useAuth();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      handleGetProduct(id);
    }
  }, [id]);

  const handleGetProduct = async (productId) => {
    try {
      const product = await getProductById(productId);
      setProduct(product);
      setLoading(false);
    } catch (err) {
      console.log('Error fetching product:', err);
      setLoading(false);
    }
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const customerId = session.user.customerId;

    if (!customerId) {
      console.error('No customer ID found in session');
      return;
    }

    console.log('product here: ', product)

    try {
      const mode = product.isRecurring ? 'subscription' : 'payment';  // Determine the mode based on the product
      const sessionId = await createCheckoutSession(product.default_price, mode, customerId);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className='checkout'>
      <h1>{product.name}</h1>
      {product.images && product.images.length > 0 && (
        <img src={product.images[0]} alt={product.name} />
      )}
      <p>{product.description}</p>
      <p>Price: ${product.price.toFixed(2)}</p>
      <button onClick={handleCheckout}>Proceed to Checkout</button>
      <button onClick={() => navigate(-1)}>Go Back</button>
    </div>
  );
};

export default Checkout;
