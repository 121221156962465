import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import PrimaryButton from '../../components/PrimaryButton';
import PrimaryInput from '../../components/PrimaryInput';
import theme from '../../styles';
import triangle from '../../assets/triangle.svg';
import { register } from '../../api/register';
import Swal from 'sweetalert2';
import Lottie from "lottie-react";
import LoadingAnimation from '../../assets/lottie/Animation - 1719783273631.json';

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    passwordCheck: '',
    fullName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { email, password, passwordCheck, fullName } = credentials;

    if (password !== passwordCheck) {
      Swal.fire('Error', 'Las contraseñas no coinciden', 'error');
      return;
    }

    setLoading(true);

    try {
      // Step 1: Check if user already exists in Strapi
      const userExists = await checkUserExistsInStrapi(email);

      if (userExists) {
        Swal.fire('Error', 'Ya existe un usuario con este correo electrónico', 'error');
        setLoading(false);
        return;
      }

      // Step 2: Create Stripe customer if the user does not exist in Strapi
      const customerId = await createStripeCustomer(email, fullName);

      if (!customerId) {
        Swal.fire('Error', 'Ya existe un cliente con este correo electrónico en Stripe', 'error');
        setLoading(false);
        return;
      }

      // Step 3: Register the user in Strapi with the Stripe customer ID
      const userData = JSON.stringify({
        username: email,
        email,
        password,
        customerId,
        fullName,
      });

      const response = await register(userData);

      if (response && response.jwt) {
        Swal.fire('Éxito', 'Registro completado', 'success');
        navigate('/login');
      } else if (response && response.error) {
        if (
          response.error.name === 'ApplicationError' &&
          response.error.message.includes('Email or Username are already taken')
        ) {
          Swal.fire('Error', 'El correo electrónico o nombre de usuario ya está en uso', 'error');
        } else {
          Swal.fire('Error', 'Registro fallido', 'error');
        }
      } else {
        Swal.fire('Error', 'Registro fallido', 'error');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      Swal.fire('Error', 'Ocurrió un error durante el registro', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Function to check if the user exists in Strapi
  const checkUserExistsInStrapi = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users?filters[email][$eq]=${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      const users = await response.json();

      console.log('users: ', users)

      return users.length > 0; // If any user is found, return true
    } catch (error) {
      console.error('Error checking user existence in Strapi:', error);
      throw error;
    }
  };

  const createStripeCustomer = async (email, fullName) => {
    try {
      const existingCustomersResponse = await fetch(
        `https://api.stripe.com/v1/customers?email=${encodeURIComponent(email)}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      const existingCustomers = await existingCustomersResponse.json();

      if (existingCustomers.data && existingCustomers.data.length > 0) {
        return null;
      } else {
        const newCustomerResponse = await fetch(
          'https://api.stripe.com/v1/customers',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              email,
              name: fullName || email,
            }),
          }
        );
        const newCustomer = await newCustomerResponse.json();

        if (!newCustomer.id) {
          throw new Error('Failed to create Stripe customer');
        }

        return newCustomer.id;
      }
    } catch (error) {
      console.error('Error creating or retrieving Stripe customer:', error);
      throw error;
    }
  };

  const isFormValid = credentials.email && credentials.password && credentials.passwordCheck;

  return (
    <MainContainer>
      {loading ? (
        <LoadingContainer>
          <Lottie animationData={LoadingAnimation} loop={true} style={{ maxWidth: '200px', margin: 50 }} />
          <LoadingText>Estamos creando tu cuenta...</LoadingText>
        </LoadingContainer>
      ) : (
        <FormContainer onSubmit={handleRegister}>
          <H2>Boulder Grid</H2>
          <PrimaryInput
            type='text'
            nameValue='fullName'
            value={credentials.fullName}
            onChange={handleChange}
            label='Nombre y Apellido (Opcional)'
          />
          <PrimaryInput
            type='email'
            nameValue='email'
            value={credentials.email}
            onChange={handleChange}
            label='Email'
          />
          <PrimaryInput
            type='password'
            nameValue='password'
            value={credentials.password}
            onChange={handleChange}
            label='Contraseña'
          />
          <PrimaryInput
            type='password'
            nameValue='passwordCheck'
            value={credentials.passwordCheck}
            onChange={handleChange}
            label='Confirma tu contraseña'
          />
          <PrimaryButton
            type='submit'
            width={'100%'}
            backgroundColor={theme.color.accent}
            color={theme.color.textPrimary}
            disabled={!isFormValid}
          >
            Registrarse
          </PrimaryButton>
        </FormContainer>
      )}
      <Image src={triangle} alt='triangle-on-background' />
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
}));

const LoadingText = styled('p')(() => ({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '500',
  fontSize: '18px',
  color: theme.color.textPrimary,
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
}));

const FormContainer = styled('form')({
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '25px',
  padding: '100px 0',
});

const Image = styled('img')({
  width: '100%',
  marginBottom: '-1px',
});

export default Register;
