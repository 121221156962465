// theme.js

const theme = {
  color: {
    primary: '#144773',
    secondary: '#2ecc71',
    background: '#ecf0f1',
    textPrimary: '#444444',
    textSecondary: '#95a5a6',
    blue: '#5CB4F3',
    babyBlue: '#96CAF2',
    accent: '#F3C055',
    light: '#FFFFF0',
    green: '#008069',
    disabledBackground: '#F3C055',
    // Add more colors as needed
  },
  fontFamily: {
    primary: 'Inter',
    // Add more fonts as needed
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
    // Add more spacing values as needed
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    // Add more border radius values as needed
  },
  // Add any other theme values you need
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export default theme;
