import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import NoMatch from '../pages/NoMatch';
import ProtectedRoute from './protectedRoutes.js'
import Admin from '../pages/Admin';
import TermsAndConditions from '../pages/TermsAndConditions/index.js';
import PrivacyNotice from '../pages/PrivacyNotice/index.js';
import Register from '../pages/Register/index.js';
import ProductDetail from '../pages/ProductDetail';
import Checkout from '../pages/Checkout';
import Success from '../pages/Success/index.js';
import Cancel from '../pages/Cancel/index.js';

function Router() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path='home' element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='register' element={<Register />} />
      <Route path='/product/:id' element={<ProductDetail />} />
      <Route
        path='/checkout/:id'
        element={
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        }
      />
      <Route
        path='dashboard'
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path='admin'
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route path='success' element={<Success />} />
      <Route path='cancel' element={<Cancel />} />
      <Route path='terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='privacy-notice' element={<PrivacyNotice />} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}

export default Router;
