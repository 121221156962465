import { useState } from 'react';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles';

const PrimaryInput = (props) => {
  const { width, onClick, children, onChange, value, type, nameValue, label } = props;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputContainer>
      <StyledTextField
        id={nameValue}
        type={showPassword ? 'text' : type}
        name={nameValue}
        value={value}
        onChange={onChange}
        placeholder=''
      />
      <Label htmlFor={nameValue}>{label}</Label>
      {type === 'password' && (
        <IconContainer onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </IconContainer>
      )}
    </InputContainer>
  );
};

const InputContainer = styled('div')({
  width: '100%',
  maxWidth: '400px',
  position: 'relative',
  borderRadius: '10px',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
});

const StyledTextField = styled('input')({
  width: '100%',
  padding: '14px 12px 14px',  // Added left padding for the icon
  borderRadius: '10px',
  backgroundColor: '#fff',
  transition: 'all 0.3s ease',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#ECECEC',
  },
  '&:focus': {
    outline: 'none',
    border: `2px solid ${theme.color.light}`,
    boxShadow: '0 0 0 2px #5CB4F3',
  },
  '&:focus + label, &:not(:placeholder-shown) + label': {
    top: '0',
    left: '12px',
    fontSize: '12px',
    color: '#5CB4F3',
  },
});

const IconContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  right: '12px',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  color: '#888',
});

const Label = styled('label')({
  position: 'absolute',
  left: '12px',
  top: '12px',
  color: '#888',
  transition: 'all 0.2s ease',
  pointerEvents: 'none',
});

export default PrimaryInput;
