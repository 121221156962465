import React, { useEffect, useState } from 'react';
import logo from '../../assets/boulder-grid-logotype.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { ClickAwayListener, Drawer } from '@mui/material';
import PrimaryButton from '../PrimaryButton';
import { useScroll } from '../ScrollContext';
import useAuth from '../../auth/useAuth';
import Swal from 'sweetalert2';
import theme from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ position }) => {
    const { home, memberships, areas, clases, events, contactForm, faq } = useScroll();
    const { session, onLogout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (location.hash === '#areas') {
            scrollToSection(areas);
        }
    }, [location, areas]);

    useEffect(() => {
        if (location.hash === '#memberships') {
            scrollToSection(memberships);
        }
    }, [location, memberships]);

    useEffect(() => {
        if (location.hash === '#clases') {
            scrollToSection(clases);
        }
    }, [location, clases]);

    useEffect(() => {
        if (location.hash === '#events') {
            scrollToSection(events);
        }
    }, [location, events]);

    useEffect(() => {
        if (location.hash === '#contact') {
            scrollToSection(contactForm);
        }
    }, [location, contactForm]);

    useEffect(() => {
        if (location.hash === '#faq') {
            scrollToSection(faq);
        }
    }, [location, faq]);

    const scrollToSection = (sectionRef) => {
        window.scrollTo({
            top: sectionRef.current.offsetTop - 73,
            behavior: 'smooth'
        });
    };

    const handleNavClick = (sectionRef, url) => {
        if (location.pathname !== '/' && location.pathname !== '/home') {
            navigate(url);
        } else {
            scrollToSection(sectionRef);
        }
        setMenuOpen(false); // Close the menu when a navigation item is clicked
    };

    const handleLogout = () => {
        onLogout();
        Swal.fire({
            title: 'Sesión cerrada',
            text: 'Tu sesión se ha cerrado correctamente.',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: theme.color.accent,
        });
        setMenuOpen(false); // Close the menu when logout is clicked
    };

    return (
        <MainContainer position={position} scrollPosition={scrollPosition}>
            <LogoContainer>
                <NavLink to="/" onClick={() => handleNavClick(home, '/#home')}>
                    <Logo src={logo} alt="Logo" />
                </NavLink>
            </LogoContainer>
            <NavLinksContainer>
                <NavLinks>
                    <NavLinkStyled to="/#home" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(home, '/#home');
                    }}>Inicio</NavLinkStyled>
                    <NavLinkStyled to="/#areas" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(areas, '/#areas');
                    }}>Áreas</NavLinkStyled>
                    <NavLinkStyled to="/#memberships" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(memberships, '/#memberships');
                    }}>Membresías</NavLinkStyled>
                    <NavLinkStyled to="/#clases" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(clases, '/#clases');
                    }}>Clases</NavLinkStyled>
                    <NavLinkStyled to="/#events" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(events, '/#events');
                    }}>Eventos</NavLinkStyled>
                    <NavLinkStyled to="/#contact" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(contactForm, '/#contact');
                    }}>Contacto</NavLinkStyled>
                    <NavLinkStyled to="/#faq" onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(faq, '/#faq');
                    }}>FAQ</NavLinkStyled>
                </NavLinks>
            </NavLinksContainer>
            <AuthButtonContainer>
                {session ? (
                    ['/', '/home', '/terms-and-conditions', '/privacy-policy'].includes(location.pathname) ? (
                        <NavLink to='/dashboard'>
                            <PrimaryButton padding='10px 20px' backgroundColor={theme.color.accent} color={theme.color.textPrimary}>Mi cuenta</PrimaryButton>
                        </NavLink>
                    ) : (
                        <PrimaryButton onClick={handleLogout} padding='10px 20px' backgroundColor={theme.color.accent} color={theme.color.textPrimary}>Cerrar sesión</PrimaryButton>
                    )
                ) : (
                    <NavLink to='/login'>
                        <PrimaryButton padding='10px 20px' backgroundColor={theme.color.accent} color={theme.color.textPrimary}>Iniciar sesión</PrimaryButton>
                    </NavLink>
                )}
            </AuthButtonContainer>
            <HamburgerButton
                type='button'
                onClick={() => setMenuOpen(true)}
                title='menu icon'
            >
                <FontAwesomeIcon icon={faBars} />
            </HamburgerButton>
            <Drawer open={menuOpen} anchor='top'>
                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <AccordionContainer>
                        <CloseButton

                            type='button'
                            onClick={() => setMenuOpen(false)}
                            title='menu icon'
                        >
                            <FontAwesomeIcon icon={faTimes} size='xl' />
                        </CloseButton>
                        <NavLinkStyled to="/#home" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(home, '/#home');
                        }}>Inicio</NavLinkStyled>
                        <NavLinkStyled to="/#areas" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(areas, '/#areas');
                        }}>Áreas</NavLinkStyled>
                        <NavLinkStyled to="/#memberships" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(memberships, '/#memberships');
                        }}>Membresías</NavLinkStyled>
                        <NavLinkStyled to="/#clases" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(clases, '/#clases');
                        }}>Clases</NavLinkStyled>
                        <NavLinkStyled to="/#events" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(events, '/#events');
                        }}>Eventos</NavLinkStyled>
                        <NavLinkStyled to="/#contact" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(contactForm, '/#contact');
                        }}>Contacto</NavLinkStyled>
                        <NavLinkStyled to="/#faq" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick(faq, '/#faq');
                        }}>FAQ</NavLinkStyled>

                        {session ? (
                            <PrimaryButton onClick={handleLogout} padding='10px 20px' backgroundColor={theme.color.accent} color={theme.color.textPrimary}>Cerrar sesión</PrimaryButton>
                        ) : (
                            <NavLink to='/login'>
                                <PrimaryButton padding='10px 20px' backgroundColor={theme.color.accent} color={theme.color.textPrimary}>Iniciar sesión</PrimaryButton>
                            </NavLink>
                        )}
                    </AccordionContainer>
                </ClickAwayListener>
            </Drawer>
        </MainContainer>
    );
};

// Styled Components
const MainContainer = styled('div')(({ position, scrollPosition }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 50,
    padding: '20px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: scrollPosition !== 0 ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.6)',
    backdropFilter: position === 'sticky' || scrollPosition !== 0 ? 'blur(10px)' : 'none',
    boxShadow: scrollPosition !== 0 ? '0 4px 10px 0px rgba(0, 0, 0, 0.25)' : 'none',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    color: 'black',
}));

const commonWidth = '16.67%'; // Shared width between LogoContainer and AuthButtonContainer

const LogoContainer = styled('div')({
    width: commonWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: 1064px)`]: {
        width: 'auto',
    },
});

const AccordionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0 30px',
    gap: 20,
});

const Logo = styled('img')({
    height: '48px',
});

const NavLinksContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    [`@media (max-width: 1064px)`]: {
        display: 'none', // Hide on smaller screens
    },
});

const NavLinks = styled('div')({
    display: 'flex',
    gap: '40px',
});

const NavLinkStyled = styled(NavLink)({
    color: theme.color.textPrimary,
    textDecoration: 'none',
    fontWeight: '700',
    transition: 'color 0.3s ease',
    '&:hover': {
        color: theme.color.accent,
    },
});

const AuthButtonContainer = styled('div')({
    width: commonWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: 1064px)`]: {
        display: 'none', // Hide on smaller screens
    },
});

const HamburgerButton = styled('button')(() => ({
    display: 'none',
    [`@media (max-width: 1064px)`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '20px',
        color: theme.color.primaryText,
        backgroundColor: 'transparent',
        fontSize: '18px',
        border: 'none',
    },
}));
const CloseButton = styled('button')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '40px',
    color: theme.color.primaryText,
    fontSize: '18px',
    border: 'none',

}));

export default Navbar;
