import { useState } from 'react';
import useAuth from '../../auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import PrimaryButton from '../../components/PrimaryButton';
import PrimaryInput from '../../components/PrimaryInput';
import theme from '../../styles';
import triangle from '../../assets/triangle.svg';

function Login() {
  const { onLogin } = useAuth();
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    user: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const { user, password } = credentials;
    onLogin(user, password);
  };

  return (
    <MainContainer>
      <FormContainer onSubmit={handleLogin}>
        <H2>Boulder Grid</H2>
        <PrimaryInput
          type='text'
          nameValue='user'
          value={credentials.user}
          onChange={handleChange}
          label='Usuario'
        />
        <PrimaryInput
          type='password'
          nameValue='password'
          value={credentials.password}
          onChange={handleChange}
          label='Contraseña'
        />
        <PrimaryButton
          type='submit'
          width={'100%'}
          backgroundColor={theme.color.accent}
          color={theme.color.textPrimary}
        >
          Iniciar sesión
        </PrimaryButton>
        <PrimaryButton
          type='button'
          onClick={() => {
            navigate('/register');
          }}
          width={'100%'}
          backgroundColor={theme.color.primary}
          color={theme.color.light}
        >
          Crear cuenta
        </PrimaryButton>
      </FormContainer>
      <Image src={triangle} alt='triangle-on-background' />
    </MainContainer>
  );
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
}));

const FormContainer = styled('form')({
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '25px',
  padding: '100px 0',
});

const Image = styled('img')({
  width: '100%',
  marginBottom: '-1px',
});

export default Login;
