import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
import triangle from '../../assets/triangle.svg';
import useAuth from '../../auth/useAuth';
import { getStripeTransactions } from '../../api/products'; // Updated API function
import Lottie from 'lottie-react';
import LoadingAnimation from '../../assets/lottie/Animation - 1719783273631.json';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';

function Dashboard() {
  const { session } = useAuth();
  const userName = session?.user?.fullName || session?.user?.email;
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const customerId = session?.user?.customerId;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        if (customerId) {
          const transactions = await getStripeTransactions(customerId);
          const sortedTransactions = transactions.sort(
            (a, b) => new Date(b.created * 1000) - new Date(a.created * 1000)
          );
          setTransactions(sortedTransactions);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [customerId]);

  return (
    <MainContainer>
      <ContentContainer>
        <H2>¡Bienvenido{userName ? `, ${userName}` : ''}!</H2>
        {loading ? (
          <Lottie
            animationData={LoadingAnimation}
            loop={true}
            style={{ maxWidth: '200px', margin: 50 }}
          />
        ) : (
          <TransactionList>
            {transactions.length > 0 ? (
              transactions.map((transaction) => (
                <TransactionItem key={transaction.id}>
                  <strong>
                    {new Date(transaction.created * 1000).toLocaleDateString()}
                  </strong>
                  <p>
                    Monto: ${transaction.amount / 100}{' '}
                    {transaction.currency.toUpperCase()}
                  </p>
                  {transaction.product_name && (
                    <p>
                      Nombre:{' '}
                      {transaction.product_name || 'Producto no encontrado'}
                    </p>
                  )}
                  {transaction.product_description && (
                    <p>
                      Descripción:{' '}
                      {transaction.product_description ||
                        'Descripción no disponible'}
                    </p>
                  )}
                </TransactionItem>
              ))
            ) : (
              <NoTransactionsContainer>
                <p>No hay transacciones disponibles.</p>
                <PrimaryButton
                  onClick={() => navigate('/#memberships')}
                  color={theme.color.textPrimary}
                  padding='10px 20px'
                >
                  Ver Membresías
                </PrimaryButton>
              </NoTransactionsContainer>
            )}
          </TransactionList>
        )}
      </ContentContainer>
      <Image src={triangle} alt='triangle-on-background' />
    </MainContainer>
  );
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  color: theme.color.textPrimary,
  textAlign: 'center',
}));

const ContentContainer = styled('div')({
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  padding: '100px 0',
});

const TransactionList = styled('div')({
  width: '100%',
  marginTop: '20px',
  padding: 50,
  backgroundColor: '#fff',
});

const TransactionItem = styled('div')({
  borderBottom: '1px solid #ccc',
  padding: '10px 0',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const NoTransactionsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 50,
});

const Image = styled('img')({
  width: '100%',
  marginBottom: '-1px',
});

export default Dashboard;
